/**
 * Created by 宇 on 2019/5/31.
 */
let qrfDom = require("./qrfDom");
let domainSchool =  _process_env.http+"://"+_process_env.url+":"+_process_env.port;
let domainStudyStudent = _process_env.http+"://"+_process_env.url+":"+_process_env.port;
const ContextConf = {
    time:15000,
    data:{
    },
    setHeader:(data,conf)=>{
    },
    prepare:(bindData,conf)=>{
        if(!bindData)return {}
        var params = {
            header:{
                iserial:q_U.iserial(),
                ikey:"qrf",
                module:"school"
            },
            body:{}
        };
        qhttp.extend(params.body,bindData);
        return params;
    },
    bindServerSchema:{
        "login":{
            url:"/authUser/mobile/loginByEmail",
            time:15000,
            reqSchema:{
                "email":function(dState,fState){
                    var email = qrfDom.getVal("email");
                    console.log(email);
                    dState.email = email+":"+_process_env.tenantId;
                },
                "password":"password",
            },
            resSchema:{
                "user":"body"
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_.call("#q_loading","close",{});
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        q_.call("#q_loading","close",{});
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.call("#q_loading","close",{});
                        q_Toast("back error："+msg,1000);
                        throw msg;
                    }
                }
                //清空密码，避免泄露
                qrfDom.set("password","");//避免泄露
                qhttp.modVarValue(conf.resSchema,data);

                if(window.native && window.native.addBury){
                    let userId = data.body.userId;
                    window.native.addBury(JSON.stringify({
                        name:"loginSucc",value:1,content:"emailByEmailSucc userId:"+userId,
                    }));
                }
                qDom.eves.loginSucc();
            },
            error:function(err){
                q_.call("#q_loading","close",{});
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        signUp:{
            url:"/authUser/mobile/signUpByEmail",
            time:15000,
            reqSchema:{
                "signEmail":"email",
                "tenantId":function(dState,fState){
                    dState.tenantId = _process_env.tenantId;
                },
                "signPassword":"password",
                username:"username",
                status:"status",
                expire:"expire",
                enabled:"enabled",
                locked:"locked",
                loginFailTimes:"loginFailTimes",
                loginMaxTimes:"loginMaxTimes",
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_.call("#q_loading","close",{});
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        q_.call("#q_loading","close",{});
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.call("#q_loading","close",{});
                        q_Toast("back error："+msg,1000);
                        throw msg;
                    }
                }
                qrfDom.set("email",qrfDom.getVal("signEmail"));
                qrfDom.set("password",qrfDom.getVal("signPassword"));
                qrfDom.set("signPassword","");//避免泄露
                qrfDom.set("isFirstLogin",true);
                qrfDom.set("loginShow",false);
                qrfDom.eves.login();
            },
            error:function(err){
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        loginByThirdId:{
            url:"/authUser/mobile/loginByThirdId",
            time:15000,
            reqSchema:{
                thirdStat:{
                    "thirdType":"type",
                    "thirdId":"thirdId",
                    "thirdEmail":"email",
                    "thirdHeadImg":"avatar",
                    "thirdName":"nickname",
                    "thirdGender":"gender",
                },
                "tenantId":function(dState,fState){
                    console.log("_process_env.tenantId:"+_process_env.tenantId);
                    dState.tenantId = _process_env.tenantId;
                },
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_Toast("back error："+msg,1000);
                        throw msg;
                    }
                }
                qhttp.post("addNicknameToStudent");
                let body = data.body;
                let isFirstLogin = !body.isSignUp;
                console.log(body.isSignUp,isFirstLogin);
                qrfDom.set("isFirstLogin",isFirstLogin);
                qDom.eves.loginSucc();
            },
            error:function(err){
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        getStudentInfo:{
            url:"/student/getSelfInfo",
            time:15000,
            reqSchema:{
            },
            resSchema:{
            },
            cacheType:"local",
            cacheLevel:1,
            cacheName:"getSelfInfo",
            success:(qDom,data,conf)=>{
                q_.call("#q_loading","close",{});
                if(data.header == null){
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_Toast("back error："+msg,1000);
                        throw data.header.msg;
                    }
                }
                let body = data.body;
                if(null != body){//没有登记过信息
                    qrfDom.eves.getStudentInfo(body);
                }
                else{
                    console.warn("Have not studied even!");
                }
            },
            error:(err)=>{
                q_.call("#q_loading","close",{});
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        "getIdentify":{
            url:"/authUser/mobile/identity/get",
            time:15000,
            reqSchema:{
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_Toast("back error："+msg,1000);
                        throw msg;
                    }
                    qrfDom.eves.getIdentifySucc(data.body);
                }
            },
            error:(err)=>{
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        "addNicknameToStudent":{
            url:"/student/addInfo",
            time:15000,
            reqSchema:{
                editInfo:{
                    nickname:"nickname",
                    gender:"gender",
                    avatar:"avatar",
                },
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                q_.call("#q_loading","close",{});
                if(data.header == null){
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_Toast("back error："+msg,1000);
                        throw msg;
                    }
                }
            },
            error:(err)=>{
                q_.call("#q_loading","close",{});
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        "signupAct":{
            url:"/student/signupAct",
            time:15000,
            reqSchema:{
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_.call("#q_loading","close",{});
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        q_.call("#q_loading","close",{});
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.call("#q_loading","close",{});
                        q_Toast("back error："+msg,1000);
                        throw msg;
                    }
                }
            },
            error:(err)=>{
                q_.call("#q_loading","close",{});
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        "signInfoAct":{
            url:"/student/signInfoAct",
            time:15000,
            reqSchema:{
            },
            resSchema:{
            },
            success:(qDom,data,conf)=>{
                if(data.header == null){
                    q_.call("#q_loading","close",{});
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        q_.call("#q_loading","close",{});
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        q_.call("#q_loading","close",{});
                        q_Toast("back error："+msg,1000);
                        throw msg;
                    }
                    qrfDom.eves.signInfoActSucc();
                }
            },
            error:(err)=>{
                qrfDom.set("loginShow",false);
                q_.call("#q_loading","close",{});
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
        "getCommodities":{
            url:"/commodity/getCommodities",
            time:15000,
            prepare:function(bindData,params){
                params.header.trscode="schoolGetCommodities";
                return params;
            },
            reqSchema:{
            },
            resSchema:{
            },
            cacheType:"local",
            cacheLevel:3,
            cacheName:"getCommodities",
            success:function(qDom,data,conf){
                if(data.header == null){
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        // q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw msg;
                    }
                }
            },
        },
        "havenCommodities":{
            url:"/commodity/havenCommodities",
            time:15000,
            prepare:function(bindData,params){
                params.header.trscode="schoolHavenCommoditiesInit";
                return params;
            },
            reqSchema:{
            },
            resSchema:{
            },
            cacheType:"local",
            cacheLevel:3,
            cacheName:"havenCommodities",
            success:function(qDom,data,conf){
                if(data.header == null){
                    q_Toast("back error：An error occurred. Please try again",1000);
                    throw "后台返回异常信息："+data;
                }
                else{
                    let code = data.header.code;
                    let msg = data.header.msg;
                    if(code == -120){
                        throw "重复提交请求";
                    }
                    else if(code != 0){
                        // q_.notisfyEvent("showMessageBox",{title:"error",content:"back error："+msg});
                        throw msg;
                    }
                }
            },
            error:function(err){
                q_Toast("back error：An error occurred. Please try again",1000);
                throw err;
            }
        },
    }
};
const qhttp = new q_Http(ContextConf,qrfDom);
module.exports = qhttp;
