q_style.writeStyle("qrf_login","qrflogin",{
    '.emailsignin':{
        position:"absolute",
            top:"calc(50% - 432px)",
            left:"calc(50% - 400px)",
            width:"800px",
            height:"864px",
            "z-index": "10",
    },
    '.signin': {
        position:"absolute",
        top:"calc(50% - 432px)",
        left:"calc(50% - 400px)",
        width:"800px",
        height:"864px",
        "z-index": "10",
    },
    '.guideSign': {
        position:"absolute",
        top:"190px",
        left:"191px",
        width:"1538px",
        height:"700px",
        "z-index": "10",

    },
    '.signUserInfo': {
        position:"absolute",
        top:"108px",
        left:"190px",
        width:"1540px",
        height:"862px",
        "z-index": "10",

    },
    '.signin_form': {
        position:"relative",
        "background-color": "#FFE9C3",
        top:"0",
        left:"0",
        padding:"0",
        width:"100%",
        height:"100%",
        border:"1px solid transparent",
        "border-radius":"10px",
        ".logo_controller":{
            position:"absolute",
            top:"-22px",
            left:"320px",
            width:"160px",
            height:"160px",
            ".login_image":{
                width:"100%",
                height:"100%",
            }
        },
        ".facebook_but_controller":{
            position:"absolute",
            top:"260px",
            left:"80px",
            width:"632px",
            height:"80px",
            "img":{
                width:"632px",
                height:"80px",
                background: "#FFFFFF",
                "border-radius": "8px",
            }
        },
        ".google_but_controller":{
            position:"absolute",
            top:"392px",
            left:"80px",
            width:"632px",
            height:"80px",
            "img":{
                width:"632px",
                height:"80px",
                background: "#FFFFFF",
                "border-radius": "8px",
            }
        },
        ".email_but_controller":{
            position:"absolute",
            top:"524px",
            left:"80px",
            width:"632px",
            height:"80px",
            "img":{
                width:"632px",
                height:"80px",
                background: "#FFFFFF",
                "border-radius": "8px",
            }
        },
        ".email_controller":{
            position:"absolute",
            top:"274px",
            left:"80px",
            width:"640px",
            height:"88px",
            "border-radius": "12px",
            ".login_email":{
                "font-size":"28px","font-family":"Roboto-my","font-weight":"400",
                "padding-left":"24px",
                width:"calc(100% - 26px)",
                height:"calc(100% - 2px)",
                background: "#FFF6E6",
                "border":"1px solid #572D1C",
                "border-radius": "12px",
                "&:-internal-autofill-selected":{
                    background: "#FFF6E6",
                    "border":"1px solid #572D1C",
                    "border-radius": "12px",
                },
            }
        },
        ".password_controller":{
            position:"absolute",
            top:"382px",
            left:"80px",
            width:"640px",
            height:"88px",
            "border-radius": "12px",
            ".login_password":{
                "font-size":"28px","font-family":"Roboto-my","font-weight":"400",
                "padding-left":"24px",
                width:"calc(100% - 26px)",
                height:"calc(100% - 2px)",
                background: "#FFF6E6",
                "border":"1px solid #572D1C",
                "border-radius": "12px",
                "&:-internal-autofill-selected":{
                    background: "#FFF6E6",
                    "border":"1px solid #572D1C",
                    "border-radius": "12px",
                },
            }
        },
        ".button_controller":{
            position:"absolute",
            top:"502px",
            left:"80px",
            width:"640px",
            height:"88px",
            ".login_button":{
                "font-size":"28px","font-family":"Roboto-my","font-weight":"400",
                "color":"#F7B500",
                width:"100%",
                "border-radius": "44px",
                height:"100%",
                background: "#562C1C",
                "text-align":"center",
            }
        },
        ".login_or_line":{
            position:"absolute",
            left:"79px",
            top:"586px",
            width:"640px",
            height:"1px",
            "background-color":"rgba(87,45,28,0.2)",
        },
        ".login_or":{
            position:"absolute",
            "background-color": "#FFE9C3",
            left:"calc(50% - 36px)",
            top:"-10px",
            width:"72px",
            height:"28px",
            "background-color":"#FFE9C3",
            "font-size":"24px","font-family":"Roboto-my","font-weight":"400","color":"#000000",
            "text-align":"center"
        },
        ".third_controller":{
            position:"absolute",
            left:"84px",
            top:"631px",
            height:"78px",
            width:"640px",
            ".facebook_button":{
                position:"absolute",
                left:"0",
                width:"258px",
                height:"70px",
                "border":"4px solid #ffffff",
                "border-radius": "12px",
                "img":{
                    width:"100%",height:"100%",
                }
            },
            ".google_button":{
                position:"absolute",
                right:"0",
                width:"250px",
                height:"70px",
                "border":"4px solid #ffffff",
                "border-radius": "12px",
                "color":"#B9850B",
                "img":{
                    width:"100%",height:"100%",
                }
            },
        },
        ".newaccount_controller":{
            position:"absolute",
            top:"777px",
            left:"263px",
            width:"282px",
            height:"33px","text-align":"center","line-height":"33px",
            "font-size":"24px","font-family":"Roboto-my","font-weight":"bold",color:"#572D1C",
        },
        ".back_controller":{
            position:"absolute",
            top:"66px",
            left:"52px",
            width:"44px",
            height:"44px",
            "img":{
                width:"100%",
                height:"100%",
            }
        },
        ".signup_email_controller":{
            position:"absolute",
            top:"250px",
            left:"80px",
            width:"640px",
            height:"88px",
            "border-radius": "12px",
            ".login_email":{
                "font-size":"28px","font-family":"Roboto-my","font-weight":"400",
                "padding-left":"24px",
                width:"calc(100% - 26px)",
                height:"calc(100% - 2px)",
                background: "#FFF6E6",
                "border":"1px solid #572D1C",
                "border-radius": "12px",
                "&:-internal-autofill-selected":{
                    background: "#FFF6E6",
                    "border":"1px solid #572D1C",
                    "border-radius": "12px",
                },
            }
        },
        ".signup_password_controller":{
            position:"absolute",
            top:"358px",
            left:"80px",
            width:"640px",
            height:"88px",
            "border-radius": "12px",
            ".login_password":{
                "font-size":"28px","font-family":"Roboto-my","font-weight":"400",
                "padding-left":"24px",
                width:"calc(100% - 26px)",
                height:"calc(100% - 2px)",
                background: "#FFF6E6",
                "border":"1px solid #572D1C",
                "border-radius": "12px",
                "&:-internal-autofill-selected":{
                    background: "#FFF6E6",
                    "border":"1px solid #572D1C",
                    "border-radius": "12px",
                },
            }
        },
        ".signup_but_controller":{
            position:"absolute",
            top:"528px",
            left:"80px",
            width:"640px",
            height:"88px",
            ".login_button":{
                "font-size":"28px","font-family":"Roboto-my","font-weight":"400",
                "color":"#F7B500",
                width:"100%",
                "border-radius": "44px",
                height:"100%",
                background: "#562C1C",
                "text-align":"center",
            }
        },
        ".signup_remark_controller":{
            position:"absolute",color:"#572C1C","font-family":"Roboto-my","font-size":"22px","font-weight":"400","height":"25px", width:"378px",left:"80px",top:"466px"
        },
        ".signup_policy_controller":{
            position:"absolute",left:"125px",top:"677px",color:"#572C1C","font-family":"Roboto-my","font-size":"28px","font-weight":"bold","height":"99px",width:"550px","line-height":"33px","text-align":"center",
            ".file_for_reading":{
                color:"#FF5600",
            }
        },
        ".guidesign_welcome":{
            position:"absolute",
            top:"52px",
            left:"560px",
            width:"auto",
            height:"38px",
            "font-size":"36px","font-family":"Roboto-my","font-weight":"bold",color:"#572C1C",
        },
        ".guidesign_gift":{
            position:"absolute",
            top:"135px",
            left:"677px",
            width:"180px",
            height:"188px",
            "img":{
                width:"100%",height:"100%",
            }
        },
        ".guidesign_coin": {
            position: "absolute",
            top: "330px",
            left: "571px",
            width: "auto",
            height: "74px",
            ".text1": {
                position:"relative",color:"#572C1C","font-family":"Roboto-my","font-size":"32px","font-weight":"400","height":"25px", width:"110px",left:"0",top:"0",
            },
            ".text2": {
                position:"relative",color:"#EE4441","font-family":"Roboto-my","font-size":"64px","font-weight":"bold","height":"74px", width:"110px",left:"24px",top:"10px",
            },
            ".text3": {
                position:"relative",color:"#572C1C","font-family":"Roboto-my","font-size":"32px","font-weight":"400","height":"25px", width:"110px",left:"44px",top:"0",
            },
        },
        ".guidesign_more_coin":{
            position: "absolute",
            top: "445px",
            left: "289px",
            width: "auto",
            height: "44px",
            "font-family":"Roboto-my","font-size":"32px","font-weight":"400",
        },
        ".guidesign_more_but":{
            position: "absolute",
            top: "560px",
            left: "397px",
            width: "744px",
            height: "88px",
            ".left":{
                position: "relative",
                height: "86px",
                width: "340px",
                background:"#FFFFFF",color:"#572C1C","text-align":"center",
                "font-family":"Roboto-my","font-size":"28px","font-weight":"bold",
                float:"left","border-radius":"44px","border":"1px solid #572C1C","line-height":"86px",
            },
            ".right":{
                position: "relative",
                height: "88px",
                width: "340px",
                left:"64px",
                background:"#562C1C",color:"#F7B500","text-align":"center",
                "font-family":"Roboto-my","font-size":"28px","font-weight":"bold",
                float:"left","border-radius":"44px","line-height":"86px",
            }
        },
        ".userinfo_label":{
            position:"absolute",
            top:"52px",
            left:"660px",
            width:"300px",
            height:"38px",
            "font-size":"36px","font-family":"Roboto-my","font-weight":"bold",color:"#572C1C",
        },
        ".bar_controller":{
            position:"absolute",
            left:"64px",
            width:"1412px",
            height:"1px",
            "background-color":"rgba(87,45,28,0.2)",
        },
        ".line_controller":{
            position:"absolute",
            left:"64px",
            width:"1326px",
            height:"90px",
            "font-family":"Roboto-my","font-size":"28px","font-weight":"bold",
            ".label":{
                position:"relative",
                float:"left",
                height:"90px",
                color:"#572C1C",
                ".label_content":{
                    position:"absolute",
                    height:"88px",
                    "line-height":"88px",
                    right:"20px",
                    width:"auto",
                }
            },
            ".value":{
                position:"relative",
                float:"left",
                height:"88px",
                color:"#572C1C",
                "line-height":"88px",
                background:"#FFE0AA",
                "img":{
                    position:"relative",top:"12px",left:"24px",width:"64px",height:"64px",
                },
                ".headimg_span":{
                    position:"relative",top:"-10px",left:"calc(24px + 20px)","z-index":"1",
                },
                ".about_span":{
                    position:"absolute",top:"0",right:"30px","z-index":"1","font-size":"24px","font-family": "Roboto-my","font-weight":"bold",
                },
                "input":{
                    "font-family":"Roboto-my","font-size":"28px","font-weight":"bold",
                    position:"absolute",width:"calc(100% - 26px)",height:"calc(100% - 2px)",border:"1px solid #572D1C","border-radius":"12px","background":"#FFF6E6","font-size":"28px","padding-left":"24px",
                },
                "select":{
                    "font-family":"Roboto-my","font-size":"28px","font-weight":"bold",
                    position:"absolute",width:"100%",height:"calc(100% - 2px)",border:"1px solid #572D1C","border-radius":"12px","background":"#FFF6E6","font-size":"28px","padding-left":"24px",
                },
                ".selected":{
                    "font-family":"Roboto","font-size":"28px","font-weight":"bold",
                    position:"absolute",width:"100%",height:"86px",border:"1px solid #572D1C","border-radius":"12px","background":"#FFF6E6","font-size":"28px","padding-left":"24px",
                },
                ".arraw":{
                    position:"absolute",top:"36px",right:"24px",width:"0",height:"0","border-left":"12px solid transparent","border-right":"12px solid transparent","border-top":"17px solid #572D1C",
                    "z-index":"20",
                },
                ".options":{
                    position:"absolute",width:"100%",height:"258%",top:"100%",left:"0","z-index":"20",
                    ".option":{
                        position:"relative",width:"100%",height:"86px","border":"20px solid #fff","border-radius":"12px","z-index":"25",
                    }
                }
            },
            ".userinfo_headimg":{
                position:"absolute",
                height:"88px",
                width:"calc(258px + 277px + 14px)",
            },
            ".userinfo_nickname":{
                position:"absolute",
                height:"88px",
                left:"calc(258px + 277px + 4px)",
                width:"calc(1412px - 258px - 277px - 4px)"
            },
            ".userinfo_about":{
                position:"absolute",
                height:"88px",
                width:"1412px",
            },
            ".userinfo_gender":{
                position:"absolute",
                height:"88px",
                width:"512px",
            },
            ".userinfo_skin":{
                position:"absolute",
                height:"88px",
                width:"512px",
                left:"508px",
            },
            ".userinfo_region":{
                height:"88px",
                position:"absolute",
                width:"422px",
                left:"915px",
            },
            ".userinfo_state":{
                height:"88px",
                position:"absolute",
                width:"698px",
            },
            ".userinfo_stage":{
                position:"absolute",
                height:"88px",
                width:"842px",
                left:"508px",
            },
            ".userinfo_invitationcode":{
                position:"absolute",
                height:"88px",
                width:"1412px",

            },
        },
        ".save_controller":{
            position: "relative",
            top: "calc(16px + 30px + 34px + 52px)",
            left: "450px",
            height: "88px",
            width: "640px",
            background:"#562C1C",color:"#FFE600","text-align":"center",
            "font-family":"Roboto-my","font-size":"44px","font-weight":"bold",
            "border-radius":"44px","line-height":"88px",
        },
        ".edit-headimgfile":{
            position:"absolute",top:"0",left:"0",width:"112px",height:"88px","z-index":"20",background:"none",outline:"none",border:"none",opacity:"0",
        },
    },
    '.signUserInfoSucc': {
        position:"absolute","z-index":"101",width:"100%",height:"100%",background: "rgba(0,0,0,0)",
        ".succ_content":{
            background:"#000000","border-radius": "24px", opacity: "0.7",
            position:"absolute",left:"736px",top:"480px","width":"448px",height:"120px","text-align":"center","line-height":"120px","font-size": "32px", "font-family": "Roboto-my", "font-weight": "bold", "color": "#FFFFFF",
        }
    }
}).apply("qrf_login");