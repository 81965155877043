let qrfDom = require("./include/qrfDom");
let http = require("./include/ContextConf");
let studentStatus = require("../common/studentStatus");
let getState = require("../common/state/America");
require("../common/formCss");
require("./css/indexCss");
qrfDom.addVars({
    loginShow:false,
    signUpInShow:"signin",
    email:"",
    password:"",
    signEmail:"",
    signPassword:"",
    user:{},
    student:{},
    tenantId:"",
    username:"",
    status:1,
    expire:"9999-12-31 23:59:59",
    enabled:true,
    locked:false,
    loginFailTimes:0,
    loginMaxTimes:6,
    isAgree:true,
    isFirstLogin:false,
    signUserInfoSucc:false,
    editInfo:{
        avatar:"",
        avatarId:"",
        nickname:"",
        about:"",
        gender:"",
        skin:"",
        region:"",
        state:"",
        stage:"",
        invitationCode:"",
    },
    skinBackColor:'',
    isShowChangeSkin:false,
    aboutLength:0,
    gotGold:0,
    thirdStat:{
        "thirdType":"",
        "thirdId":"",
        "thirdEmail":"",
        "thirdName":"",
        "thirdHeadImg":"",
    }
});

qrfDom.eves.showApp=()=>{
    console.log("show====================>");
    document.getElementById("q_app").style.display="block";
};
qrfDom.eves.hiddenApp=()=>{
    console.log("hidden====================>");
    document.getElementById("q_app").style.display="none";
};
qrfDom.eves.init = ()=>{
    console.log("enter login init");
    qrfDom.eves.hiddenApp();
    //设置为竖屏
    if(window.native&&window.native.changeToPORTRAIT){
        window.native.changeToPORTRAIT();
        if(window.native.hiddenBackView){
            window.native.hiddenBackView();
        }
    }
    let times = 10;
    let ctrl = setInterval(function() {
        times --;
        if (q_style.isRotate()==false || times < 0) {
            clearInterval(ctrl);
            qrf_port_scale();
            let isLogin = q_.getLocalStorage("isLogin");
            if(!isLogin){
                qrfDom.set("loginShow",true);
                qrfDom.set("signUpInShow","signin");
                qrfDom.set("show","hidden");
            }
        }
    },100);
};
qrfDom.eves.showEmailLoginBox = ()=>{
    qrfDom.set("signUpInShow","emailsignin");
};
qrfDom.eves.hiddenLoginBox = ()=>{
    qrfDom.set("signUpInShow","signin");
    qrfDom.set("loginShow",false);
    qrfDom.set("show","school");
};
qrfDom.eves.gotoSignin = (e)=>{
    qrfDom.set("signUpInShow","signin");
};
qrfDom.eves.login=(e)=>{
    console.log("enter login");
    qrfDom.preventShake("school_signupin_login");
    let email = qrfDom.getVal("email");
    if(window.native && window.native.addBury){
        window.native.addBury(JSON.stringify({
            name:"login",value:1,content:"emailByEmail "+email
        }));
    }
    let password = qrfDom.getVal("password");
    console.log(password,qrfDom.vars);
    if(!email){
        console.log("登录邮箱不能为空");
        q_.notisfyEvent("showMessageBox",{title:"error",content:"Please input email!",event:e});
        throw "登录邮箱或密码不能为空";
    }
    if(!password){
        console.log("登录密码不能为空");
        q_.notisfyEvent("showMessageBox",{title:"error",content:"Please input password!",event:e});
        throw "登录邮箱或密码不能为空";
    }
    http.post("login");
    q_.call("#q_loading","show",{});
}
qrfDom.eves.loginSucc = ()=>{
    console.log("enter loginSucc");
    q_.setLocalStorage("isLogin",true);//标记登录成功
    qrfDom.set("isLogin",true);
    let isFirstLogin = qrfDom.getVal("isFirstLogin");
    console.log("enter loginSucc isFirstLogin:::"+isFirstLogin);
    if(isFirstLogin){
        qrfDom.eves.signupAct();
    }
    http.post("getStudentInfo");
    http.post("getIdentify");
    q_.call("#q_loading","show",{});
    //初始化商品缓存
    http.post("getCommodities");
    http.post("havenCommodities");
    setTimeout(
        function(){
            q_.call("#q_loading","close",{});
        }
        ,1000
    );
}
qrfDom.eves.getIdentifySucc = (identify)=>{
    if(identify){
        let nickname = identify.nickname;
        console.log("getIdentifySucc",nickname);
        let isFirstLogin = qrfDom.getVal("isFirstLogin");
        let state = identify.state;
        if(identify && state && state.value){
            qrfDom.set("state",state.value);
        }
        else{
            qrfDom.set("state","Alaska");
        }
        q_.setLocalStorage("user",identify);


        qrfDom.set("loginShow",false);//横屏之前进行隐藏
        //设置为横屏
        if(window.native&&window.native.changeToLANDSCAPE){
            window.native.changeToLANDSCAPE();
        }
        setTimeout(function(){
            q_.setLocalStorage("isLogin",true);
            qrf_init_scale();//这里再执行一次，避免第一次执行失败
            q_.call("#q_progress","riding",{speed:5,callback:function(){
                    qrfDom.eves.showApp();
                    q_.call("#q_school","init",{isFirstLogin:isFirstLogin});
            }});
        },500);
    }
    else{
        q_.notisfyEvent("showMessageBox",{title:"error",content:"you do not sign up!"});
    }
}
qrfDom.eves.getStudentInfo = (student)=>{
    console.log("enter getStudentInfo",student);
    q_.setLocalStorage("student",student);
    qrfDom.set("student",student);
    q_.call("#q_school","initEndStudy",{});//登录后检查学习结束情况
}
qrfDom.eves.signUp=(e)=>{
    console.log("enter signUp");
    qrfDom.preventShake("school_signupin_signup");
    // if(!qrfDom.getVal("isAgree")){
    //     console.log("not agree");
    //     q_.notisfyEvent("showMessageBox",{title:"warning",content:"please agree the agreement!",event:e});
    //     return;
    // }
    let signEmail = qrfDom.getVal("signEmail");
    if(!signEmail){
        q_.notisfyEvent("showMessageBox",{title:"error",content:"Please input email!",event:e});
        throw "Input nickname,please!";
    }
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    if(!reg.test(signEmail)){
        q_.notisfyEvent("showMessageBox",{title:"error",content:"Sorry ,this doesn't look like a valid email!",event:e});
        throw "Sorry ,this doesn't look like a valid email!";
    }
    let signPassword = qrfDom.getVal("signPassword");
    if(!signPassword){
        q_.notisfyEvent("showMessageBox",{title:"error",content:"Please input password!",event:e});
        throw "Input nickname,please!";
    }
    if(signPassword.length < 6){
        q_.notisfyEvent("showMessageBox",{title:"error",content:"The length of password must be more then 6!",event:e});
        throw "The length of password must be more then 6!";
    }
    if(signPassword.length > 18){
        q_.notisfyEvent("showMessageBox",{title:"error",content:"The length of password must be less then 18!",event:e});
        throw "The length of password must be less then 18!";
    }
    qrfDom.set("username",qrfDom.getVal("signEmail"));
    http.post("signUp");
    q_.call("#q_loading","show",{});
}
qrfDom.eves.signupAct=()=>{
    q_.call("#q_loading","close",{});
    http.post("signupAct");
};
qrfDom.eves.gotoSignup = (e)=>{
    qrfDom.set("signUpInShow","signup");
};
qrfDom.eves.gotoGuideSign = ()=>{
    // qrfDom.set("signUpInShow","guideSign");
    console.log("enter login gotoGuideSign");
    q_.call("#q_school","gotoGuideSign",{});
};
qrfDom.eves.signInfoAct=()=>{
    console.log("start signInfoAct");
    http.post("signInfoAct");
};
qrfDom.eves.signInfoActSucc=()=>{
    console.log("start signUserInfoSucc show");
    let invitationCode = qrfDom.getVal("editInfo.invitationCode");
    qrfDom.set("gotGold",888);
    if(invitationCode){
        qrfDom.set("gotGold",qrfDom.compute("gotGold + 1000"));
    }
    qrfDom.set("signUserInfoSucc",true);
    http.post("getStudentInfo");//刷新用户信息
    setTimeout(
        function(){
            qrfDom.set("signUserInfoSucc",false);
            qrfDom.set("loginShow",false);
        },2000
    );
};
qrfDom.eves.hiddenSuccWarn=()=>{
    qrfDom.set("signUserInfoSucc",false);
    qrfDom.set("loginShow",false);
};
qrfDom.eves.openTheFile1=()=>{
    q_.notisfyEvent("showReportBox",{type:"file",fileNo:1})
};
qrfDom.eves.openTheFile2=()=>{
    q_.notisfyEvent("showReportBox",{type:"file",fileNo:2})
};

qrfDom.eves.loginByGoogle=()=>{
    if(window.native && window.native.addBury){
        window.native.addBury(JSON.stringify({
            name:"login",value:1,content:"loginByGoogle"
        }));
    }
    if(typeof window.native != 'undefined' && window.native != null){
        window.native.triggerGoogleLogin();
    }
    else{
        q_Toast("The webapp does not support this opperation.");
        // qrfDom.eves.googleLoginSucc('{"clientId":"u2022123456","nickname":"曹宇","email":"cysink@123.com","headImg":"https://lh3.googleusercontent.com/a/AATXAJwyNZsoPKQ6scZ4GbXFVMHCyfDmVKaGpigy6QwO=s96-c"}')
    }
}
qrfDom.eves.loginByFacebook=()=>{
    if(window.native && window.native.addBury){
        window.native.addBury(JSON.stringify({
            name:"login",value:1,content:"loginByFacebook"
        }));
    }
    if(typeof window.native != 'undefined' && window.native != null){
        window.native.triggerFacebookLogin();
    }
    else{
        q_Toast("The webapp does not support this opperation.");
        // qrfDom.eves.facebookLoginSucc({
        //     "clientId":"u2022123456",
        //     "nickname":"曹宇",
        //     "gender":"F",
        //     "email":"cysink@123.com",
        //     "headImg":"https://t9.baidu.com/it/u=3203048949,4040482205&fm=74&app=80&size=f256,256&n=0&f=JPEG&fmt=auto?sec=1656349200&t=234e4f7bf3dc5418ad780ca3644ad5b8",
        // });
    }
}

qrfDom.eves.changeToFULLSCREEN=(e)=>{
    if(window.native&&window.native.changeToFULLSCREEN){
        console.log("start changeToFULLSCREEN");
        window.native.changeToFULLSCREEN();
    }
};
qrfDom.eves.changeToTRSNSLUCENT=(e)=>{
    if(window.native&&window.native.changeToTRSNSLUCENT){
        console.log("start changeToTRSNSLUCENT");
        window.native.changeToTRSNSLUCENT();
    }
}

if(!qrfDom.public)qrfDom.public={};
if(!qrfDom.public.eves)qrfDom.public.eves={};
qrfDom.public.eves.googleLoginSucc=qrfDom.eves.googleLoginSucc=(params)=>{
    console.log("googleLoginSucc====:"+typeof(params));
    if(window.native && window.native.addBury){
        window.native.addBury(JSON.stringify({
            name:"loginSucc",value:1,content:"googleLoginSucc thirdId:"+params["email"],
        }));
    }
    if(typeof params == 'string'){
        params = JSON.parse(params);
    }
    console.log("params====:"+params);
    if(!params["headImg"]){
        params["headImg"].replace(":-1/","/");
    }
    var stat = {
        "thirdType":"google",
        "thirdId":params["clientId"],
        "thirdName":params["nickname"],
        "thirdEmail":params["email"],
        "thirdHeadImg":params["headImg"],
        "thirdGender":params["gender"],
    }
    qrfDom.set("thirdStat",stat);
    if(params["nickname"])qrfDom.set("editInfo.nickname",params["nickname"]);
    if(params["gender"])qrfDom.set("editInfo.gender",params["gender"]);
    if(params["headImg"])qrfDom.set("editInfo.avatar",params["headImg"]);
    http.post("loginByThirdId");
}

qrfDom.public.eves.facebookLoginSucc=qrfDom.eves.facebookLoginSucc=(params)=>{
    console.log("facebookLoginSucc====:"+params);
    if(window.native && window.native.addBury){
        window.native.addBury(JSON.stringify({
            name:"loginSucc",value:1,content:"facebookLoginSucc thirdId:"+params["email"],
        }));
    }
    if(typeof params == 'string'){
        params = JSON.parse(params);
    }
    console.log("params====:"+params);
    if(!params["headImg"]){
        params["headImg"].replace(":-1/","/");
    }
    var stat = {
        "thirdType":"facebook",
        "thirdId":params["clientId"],
        "thirdName":params["nickname"],
        "thirdEmail":params["email"],
        "thirdHeadImg":params["headImg"],
        "thirdGender":params["gender"],
    }
    qrfDom.set("thirdStat",stat);
    if(params["nickname"])qrfDom.set("editInfo.nickname",params["nickname"]);
    if(params["gender"])qrfDom.set("editInfo.gender",params["gender"]);
    if(params["headImg"])qrfDom.set("editInfo.avatar",params["headImg"]);
    http.post("loginByThirdId");
}

qrfDom.definedComp("signin",`
<div qrf-if="signUpInShow=='signin'" class="signin">
    <div class="signin_form">
        <div class="logo_controller">
            <img class="login_image" src="./module/common/images/study_logo.png" />
        </div>
        <div class="facebook_but_controller" qrf-click="loginByFacebook">
            <img class="login_image" src="./module/common/images/Facebook@2x.png" />
        </div>
        <div class="google_but_controller" qrf-click="loginByGoogle">
            <img class="login_image" src="./module/common/images/Google@2x.png" />
        </div>
        <div class="email_but_controller" qrf-click="showEmailLoginBox">
            <img class="login_image" src="./module/common/images/Email@2x.png" />
        </div>
        <div class="newaccount_controller" qrf-click="gotoSignup">
            Create a new account
        </div>
    </div>
</div>
`,{});

qrfDom.definedComp("emailsignin",`
<div qrf-if="signUpInShow=='emailsignin'" class="emailsignin">
    <div class="signin_form">
        <div class="back_controller">
            <img src="./module/common/images/back-icon@3x.png" qrf-click="gotoSignin" />
        </div>
        <div class="logo_controller">
            <img class="login_image" src="./module/common/images/study_logo.png" />
        </div>
        <div class="email_controller">
            <input class="login_email" qrf-focus="changeToTRSNSLUCENT" qrf-blur="changeToFULLSCREEN" type="email" qrf-var="email" placeholder="Email" />
        </div>
        <div class="password_controller">
            <input class="login_password" qrf-focus="changeToTRSNSLUCENT" qrf-blur="changeToFULLSCREEN" type="password" qrf-var="password" placeholder="Password" />
        </div>
        <div class="button_controller">
            <button class="login_button" type="button" qrf-click="login" >Login In</button>
        </div>
        <div class="newaccount_controller" qrf-click="gotoSignup">
            Create a new account
        </div>
    </div>
</div>
`,{});

qrfDom.definedComp("signup",`
<div qrf-if="signUpInShow=='signup'" class="signin">
    <div class="signin_form">
        <div class="back_controller">
            <img src="./module/common/images/back-icon@3x.png" qrf-click="gotoSignin" />
        </div>
        <div class="logo_controller">
            <img class="login_image" src="./module/common/images/study_logo.png" />
        </div>
        <div class="signup_email_controller">
            <input class="login_email" type="email" qrf-focus="changeToTRSNSLUCENT" qrf-blur="changeToFULLSCREEN" qrf-var="signEmail" placeholder="Email" />
        </div>
        <div class="signup_password_controller">
            <input class="login_password" type="password" qrf-focus="changeToTRSNSLUCENT" qrf-blur="changeToFULLSCREEN" qrf-var="signPassword" placeholder="Password" />
        </div>
        <div class="signup_remark_controller">
            Must to be 6-18 characters
        </div>
        <div class="signup_but_controller">
            <button class="login_button" type="button" qrf-click="signUp" >Sign Up</button>
        </div>
        <div class="signup_policy_controller">
            By continuing ,you agree to StudyLand's<span class="file_for_reading" qrf-click="openTheFile1"> Terms of service </span>and ackowledge you've read our<span class="file_for_reading" qrf-click="openTheFile2"> Privacy Policy </span>
        </div>
    </div>
</div>
`,{}
);
qrfDom.definedComp("login",`
<div qrf-if="loginShow" style="position:absolute;z-index:122;width:100%;height:100%;background: url(./module/common/images/login-bg.png) no-repeat;background-size: 100% 100%;">
    <comp-signin></comp-signin>
    <comp-emailsignin></comp-emailsignin>
    <comp-signup></comp-signup>
</div>`);
qrfDom.render(`
<div class="qrflogin">
    <comp-login></comp-login>
</div>`);
if(!qrfDom.public)qrfDom.public={};
if(!qrfDom.public.eves)qrfDom.public.eves={};
qrfDom.public.eves.init = qrfDom.eves.init;
