var eleHeight = "2em";
q_style.writeStyle("qrf_form",'.qrf_form',{
    "display":"flex",
    "justify-content":"space-around",
    "width":"100%",
    "height":"auto",
    "&.row":{
        "flex-direction": "row",
    },
    "&.column":{
        "flex-direction": "column",
    },
}).writeStyle("qrf_form",'.qrf_form_item',{
    "display":"flex",
    "justify-content":"space-around",
    "width":"100%",
    "height":"auto",
    "&.row":{
        "flex-direction": "row",
    },
    "&.column":{
        "flex-direction": "column",
    },
    "&.box":{
        "width":"auto",
        "height":"2em",
        "padding":"0.1em",
        "margin":"0.1em",
        "&.q_1_w":{width:"calc(100% / 12 - 0.4em)"},
        "&.q_2_w":{width:"calc(100% / 6 - 0.4em)"},
        "&.q_3_w":{width:"calc(100% / 4 - 0.4em)"},
        "&.q_4_w":{width:"calc(100% / 3 - 0.4em)"},
        "&.q_5_w":{width:"calc(100% / 12 * 5 - 0.4em)"},
        "&.q_6_w":{width:"calc(100% / 2 - 0.4em)"},
        "&.q_7_w":{width:"calc(100% / 12 * 7 - 0.4em)"},
        "&.q_8_w":{width:"calc(100% / 3 * 2 - 0.4em)"},
        "&.q_9_w":{width:"calc(100% / 4 * 3 - 0.4em)"},
        "&.q_10_w":{width:"calc(100% / 6 * 5 - 0.4em)"},
        "&.q_11_w":{width:"calc(100% / 12 * 11 - 0.4em)"},
        "&.q_12_w":{width:"calc(100% - 0.4em)"},
        "&.q_1_h":{height:"calc(1 * "+eleHeight+")"},
        "&.q_2_h":{height:"calc(2 * "+eleHeight+")"},
        "&.q_3_h":{height:"calc(3 * "+eleHeight+")"},
        "&.q_4_h":{height:"calc(4 * "+eleHeight+")"},
        "&.q_5_h":{height:"calc(5 * "+eleHeight+")"},
        "&.q_6_h":{height:"calc(6 * "+eleHeight+")"},
        "&.q_7_h":{height:"calc(7 * "+eleHeight+")"},
        "&.q_8_h":{height:"calc(8 * "+eleHeight+")"},
        "&.q_9_h":{height:"calc(9 * "+eleHeight+")"},
        "&.q_10_h":{height:"calc(10 * "+eleHeight+")"},
        "&.q_11_h":{height:"calc(11 * "+eleHeight+")"},
        "&.q_12_h":{height:"calc(12 * "+eleHeight+")"},
    },
}).writeStyle("qrf_form",'.qrf_form_input',{
    "display":"block",
    "height":"auto",
    "width":"calc(100% - 0.6em)",
    "text-align":"center",
}).writeStyle("qrf_form",'.qrf_form_select',{
    "display":"block",
    "height":"auto",
    "width":"calc(100%)",
    "text-align":"center",
}).writeStyle("qrf_form",'.qrf_form_content',{
    "display":"block",
    "height":"auto",
    "width":"calc(100% - 0.6em)",
    "text-align":"center",
    "position":"relative",
    "top":"1em",
}).writeStyle("qrf_form",'.qrf_form_image',{
    "display":"block",
    "height":"auto",
    "width":"calc(100% - 0.6em)",
}).writeStyle("qrf_form",'.qrf_form_button',{
    "display":"block",
    "height":"auto",
    "width":"100%",
}).apply("qrf_form");
